import Icon from './Icon'

const ErrorFullIcon = (props) => (
  <Icon iconName="icon-error-full" size={16} {...props}>
    <path
    fillRule="evenodd"
    clipRule="evenodd"
    d="M8 0C3.6 0 0 3.6 0 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8Zm0 12c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1-.4 1-1 1Zm1-3H7V4h2v5Z"
    ></path>
    {props.children}
  </Icon>
)

export { ErrorFullIcon }
